<template>
  <b-row>
    <b-col lg="12">
      <h2 class="font-weight-bolder text-dark mb-4">
        Member Check In Saat Ini
      </h2>

      <div class="table-responsive">
        <!-- <div class="d-flex flex-center py-2 px-6 border bg-white rounded mb-2">
          <span class="svg-icon svg-icon-lg svg-icon-secondary">
            <inline-svg src="media/svg/icons/General/Search.svg" />
          </span>
          <b-input
            v-model="serverParams.keyword"
            type="text"
            class="form-control form-control-sm border-0 font-weight-bold pl-2"
            placeholder="Cari member"
            @input="onSearch"
          />
        </div> -->
        <Table :table="table" @onCheckOut="onSearch" />
        <Pagination
          :serverParams="serverParams"
          :table="table"
          @onPageChange="onPageChange"
        />
      </div>
    </b-col>
  </b-row>
</template>

<script>
import Table from "./Table";
import Pagination from "./Pagination";

export default {
  components: {
    Table,
    Pagination,
  },
  props: {
    serverParams: Object,
    table: Object,
  },
  methods: {
    onPageChange(params) {
      alert(params);
    },
    onSearch() {
      this.$emit("onSearch");
    },
  },
};
</script>
