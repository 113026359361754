<template>
  <div>
    <b-row>
      <b-col lg="12">
        <b-card no-body class="card-custom">
          <b-card-body>
            <Search @onCheckIn="getAllCheckIn" @onCheckOut="getAllCheckIn" />
            <MemberCheckIn
              :serverParams="serverParams"
              :table="table"
              @onSearch="getAllCheckIn"
            />
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Search from "./Search";
import MemberCheckIn from "./MemberCheckIn";

export default {
  components: {
    Search,
    MemberCheckIn,
  },
  data: () => ({
    serverParams: {
      keyword: null,
      pageNumber: 1,
      pageSize: 20,
      orderBy: null,
    },
    table: {
      isLoading: false,
      columns: [],
      rows: [],
      totalPage: 0,
      totalRecords: 0,
    },
  }),
  computed: {
    ...mapGetters(["store"]),
  },
  watch: {
    store() {
      this.getAllCheckIn();
    },
  },
  created() {
    const self = this;
    self.getAllCheckIn();
  },
  methods: {
    getAllCheckIn() {
      const self = this;

      if (self.store != null) {
        self.table.isLoading = true;
        self.$store
          .dispatch("apis/get", {
            url: `/transaction/${self.store.id}/member/check-in`,
            params: self.serverParams,
          })
          .then((response) => {
            if (response.error) {
              self.$message.error({
                zIndex: 10000,
                message: response.message,
              });
            } else {
              self.table.rows = response.data.data;
              self.table.totalPage = response.data.totalPage;
              self.table.totalRecords = response.data.totalData;
            }
          })
          .finally(() => {
            self.table.isLoading = false;
          });
      }
    },
  },
};
</script>
