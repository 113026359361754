<template>
  <fragment>
    <div class="card card-custom mb-8">
      <div class="card-body rounded p-0 d-flex bg-light">
        <div
          class="d-flex flex-column flex-lg-row-auto w-auto w-lg-550px w-xl-850px w-xxl-1000px p-5 p-md-5"
        >
          <h4 class="font-weight-bolder text-dark mb-4">
            Mau checkin atau checkout ?<br />Scan kartu member dulu ya
          </h4>
          <b-tabs content-class="p-0">
            <b-tab title="Scan Kartu Member" active>
              <div
                class="d-flex flex-center py-2 px-6 border border-top-0 bg-white rounded-bottom"
              >
                <span class="svg-icon svg-icon-lg svg-icon-primary">
                  <inline-svg src="media/svg/icons/Devices/Camera.svg" />
                </span>
                <b-input
                  v-model="rfidNumber"
                  type="password"
                  class="form-control border-0 font-weight-bold pl-2"
                  placeholder="Tarok kursor disini & silahkan scan"
                  autocomplete="off"
                  autofocus
                  @input="getMemberByRfid"
                />
              </div>
            </b-tab>
            <!-- <b-tab title="Pencarian">
              <AutoComplete
                base-class="autocomplete-dashboard"
                :search="search"
                :get-result-value="getResultValue"
                @submit="handleSubmit"
              >
                <template
                  #default="{
                    rootProps,
                    inputProps,
                    inputListeners,
                    resultListProps,
                    resultListListeners,
                    results,
                    resultProps,
                  }"
                >
                  <div v-bind="rootProps">
                    <CustomInput
                      v-bind="inputProps"
                      v-on="inputListeners"
                      :class="[
                        'autocomplete-input',
                        { 'autocomplete-input-focused': focused },
                      ]"
                    />
                    <ul v-bind="resultListProps" v-on="resultListListeners">
                      <li
                        v-for="(result, index) in results"
                        :key="resultProps[index].id"
                        v-bind="resultProps[index]"
                        class="autocomplete-result wiki-result"
                      >
                        <div
                          class="wiki-snippet"
                          v-html="result.membershipNumber"
                        />
                        <div class="wiki-title">
                          {{ result.fullName }}
                        </div>
                      </li>
                    </ul>
                  </div>
                </template>
              </AutoComplete>
            </b-tab> -->
          </b-tabs>
        </div>
        <div
          class="d-none d-md-flex flex-row-fluid bgi-no-repeat bgi-position-y-center bgi-position-x-left bgi-size-cover"
          style="background-image: url('media/svg/illustrations/copy.svg')"
        ></div>
      </div>
    </div>

    <b-modal
      v-model="modalCheckIn.show"
      title="Identitas Member"
      :no-close-on-backdrop="true"
      :ok-only="true"
      ok-title="Check In"
      @ok="handleCheckin"
    >
      <Member
        v-if="modalCheckIn.member?.id != null"
        :form="modalCheckIn.member"
      />
      <div class="separator separator-dashed border-1 mt-4"></div>
      <h4 class="font-weight-bolder mt-4">Member ini mau checkin ?</h4>
      <b-form-group label="Pilih loker dulu ya">
        <treeselect
          ref="LOCKER"
          v-model="modalCheckIn.lockerId"
          :async="true"
          :loadOptions="getAllLocker"
          placeholder="Pilih loker"
        />
      </b-form-group>
    </b-modal>

    <b-modal
      v-model="modalCheckOut.show"
      title="Identitas Member"
      :no-close-on-backdrop="true"
      :ok-only="true"
      ok-title="Check Out"
      @ok="handleCheckOut"
    >
      <Member :form="modalCheckOut.member" />
      <div class="d-flex align-items-center justify-content-between mb-2">
        <span class="font-weight-bold mr-2">Jam :</span>
        <span class="">
          {{ dateFormat(modalCheckOut.checkInTime, "HH:mm") }}
        </span>
      </div>
      <div class="d-flex align-items-center justify-content-between mb-2">
        <span class="font-weight-bold mr-2">Durasi :</span>
        <span class="">
          {{ duration(modalCheckOut.checkInTime) }}
        </span>
      </div>
    </b-modal>

    <b-modal
      v-model="modalPin.show"
      title="PIN Access"
      :no-close-on-backdrop="true"
      :ok-only="true"
      @ok="closeModalPin"
    >
      <div class="text-center">
        <p>{{ modalPin.message }}</p>
        <span class="font-size-h1">
          {{ modalPin.pin }}
        </span>
        <br />
        <img :src="modalPin.qrcodeUrl" height="200" />
      </div>
    </b-modal>
  </fragment>
</template>

<script>
import { mapGetters } from "vuex";
// import CustomInput from "./CustomInput";
import Member from "@/view/content/common/Member.vue";
import {
  dateFormat,
  dateRange,
  hoursAgo,
  duration,
  mediaUrl,
  pageSize,
} from "@/core/utils";
import { ASYNC_SEARCH } from "@riophae/vue-treeselect";

// import AutoComplete from "@trevoreyre/autocomplete-vue";
// import "@/assets/css/autocomplete.css";

export default {
  components: {
    Member,
    // CustomInput,
    // AutoComplete,
  },
  data: () => ({
    rfidNumber: null,
    keyword: null,
    modalCheckIn: {
      show: false,
      member: null,
      lockerId: null,
    },
    modalCheckOut: {
      show: false,
      member: null,
      checkInId: null,
      checkInTime: null,
    },
    modalPin: {
      show: false,
      message: null,
      qrcodeUrl: null,
      pin: null,
    },
    options: {
      locker: [],
    },

    focused: false,
  }),
  computed: {
    ...mapGetters(["store"]),
  },
  // created() {
  //   const self = this;

  //   self.getDefaultLocker();
  // },
  methods: {
    dateFormat,
    dateRange,
    hoursAgo,
    duration,
    mediaUrl,
    getDefaultLocker() {
      const self = this;

      self.$store
        .dispatch("apis/get", {
          url: `/store/${self.store.id}/locker`,
          params: {
            pageNumber: 1,
            pageSize,
            available: true,
            actived: true,
            orderBy: "label",
          },
        })
        .then((response) => {
          if (response.error) {
            self.$message.error({
              zIndex: 10000,
              message: response.message,
            });
          } else {
            self.$refs.LOCKER.defaultOptions = response.data.data.map((x) => ({
              id: x.id,
              label: x.label,
            }));
            self.$refs.LOCKER.initialize();
          }
        });
    },
    getAllLocker({ action, searchQuery, callback }) {
      const self = this;
      if (action === ASYNC_SEARCH) {
        if (self.store != null) {
          self.$store
            .dispatch("apis/get", {
              url: `/store/${self.store.id}/locker`,
              params: {
                label: searchQuery,
                pageNumber: 1,
                pageSize: 100,
                available: true,
                actived: true,
              },
            })
            .then((response) => {
              if (response.error) {
                self.$message.error({
                  zIndex: 10000,
                  message: response.message,
                });
              } else {
                // self.options.locker = response.data.data;
                callback(
                  null,
                  response.data.data.map((x) => ({
                    id: x.id,
                    label: x.label,
                  }))
                );
              }
            });
        }
      }
    },
    getMemberByRfid() {
      const self = this;

      if (self.store != null && self.rfidNumber.length == 10) {
        self.$store
          .dispatch("apis/get", {
            url: `/store/${self.store.id}/member/rfid/${self.rfidNumber}`,
          })
          .then((response) => {
            if (response.error) {
              self.$message.error({
                zIndex: 10000,
                message: response.message,
              });
            } else {
              if (response.data.checkIn == null) {
                self.getDefaultLocker();
                self.modalCheckIn.member = response.data.member;
                self.modalCheckIn.show = true;
              } else {
                self.modalCheckOut.show = true;
                self.modalCheckOut.member = response.data.member;
                /* self.modalCheckOut.customer = {
                  id: response.data.member.id,
                  fullName: response.data.member.fullName,
                  membershipNumber: response.data.member.membershipNumber,
                  email: response.data.member.email,
                  phone: response.data.member.phone,
                  memberCard: {
                    cardNumber: response.data.member.memberCard.cardNumber,
                  },
                }; */
                self.modalCheckOut.checkInId = response.data.checkIn.id;
                self.modalCheckOut.checkInTime =
                  response.data.checkIn.checkInTime;
              }
            }
          })
          .finally(() => {
            self.rfidNumber = null;
          });
      }
    },
    getMemberById(id) {
      const self = this;

      if (self.store != null) {
        self.$store
          .dispatch("apis/get", {
            url: `/store/${self.store.id}/member/${id}`,
          })
          .then((response) => {
            if (response.error) {
              self.$message.error({
                zIndex: 10000,
                message: response.message,
              });
            } else {
              self.getDefaultLocker();
              self.modal.member = response.data;
              self.modal.show = true;
            }
          });
      }
    },
    handleCheckin(event) {
      event.preventDefault();

      const self = this;

      if (self.store != null) {
        self.$dialog
          .confirm("Kamu yakin mau menambahkan data checkin ini ?", {
            okText: "Ya, Tambahkan",
            cancelText: "Ga jadi",
            loader: true,
          })
          .then((dialog) => {
            self.$store
              .dispatch("apis/post", {
                url: `/transaction/${self.store.id}/member/check-in`,
                params: {
                  memberId: self.modalCheckIn.member.id,
                  rfidNumber: self.modalCheckIn.member.memberCard.rfidNumber,
                  cardNumber: self.modalCheckIn.member.memberCard.cardNumber,
                  lockerId: self.modalCheckIn.lockerId,
                },
              })
              .then((response) => {
                if (response.error) {
                  self.$message.error({
                    zIndex: 10000,
                    message: response.message,
                  });
                } else {
                  self.modalCheckIn.member = null;
                  self.modalCheckIn.lockerId = null;
                  self.modalCheckIn.show = false;

                  self.modalPin.message = response.message;
                  self.modalPin.pin = response.data.pin;
                  self.modalPin.qrcodeUrl = `https://backend.omset.co.id/qrcode/${self.store.id}/${response.data.id}.png`;
                  self.modalPin.show = true;
                }
              })
              .finally(() => dialog.close());
          });
      }
    },
    closeModalPin() {
      const self = this;

      self.modalPin.message = null;
      self.modalPin.pin = null;
      self.modalPin.show = false;
      self.$emit("onCheckIn");
    },
    handleCheckOut(event) {
      event.preventDefault();
      const self = this;

      if (self.store != null) {
        self.$dialog
          .confirm("Member ini mau checkout ya ?", {
            okText: "Ya, Check Out",
            cancelText: "Ga jadi",
            loader: true,
          })
          .then((dialog) => {
            self.$store
              .dispatch("apis/put", {
                url: `/transaction/${self.store.id}/member/check-out/${self.modalCheckOut.checkInId}`,
              })
              .then((response) => {
                if (response.error) {
                  self.$message.error({
                    zIndex: 10000,
                    message: response.message,
                  });
                } else {
                  self.$message.success({
                    zIndex: 10000,
                    message: response.message,
                  });

                  self.modalCheckIn = {
                    show: false,
                    member: null,
                    lockerId: null,
                  };
                  self.modalCheckOut = {
                    show: false,
                    member: null,
                    checkInId: null,
                    checkInTime: null,
                  };
                  self.$emit("onCheckOut");
                }
              })
              .finally(() => dialog.close());
          });
      }
    },
    search(input) {
      const self = this;

      return new Promise((resolve) => {
        /* if (input.length < 2) {
          return resolve([]);
        } */

        if (self.store != null) {
          self.$store
            .dispatch("apis/get", {
              url: `/store/${self.store.id}/member`,
              params: {
                pageNumber: 1,
                pageSize: 5,
                keyword: input,
              },
            })
            .then((response) => {
              if (response.error) {
                return resolve([]);
              }

              resolve(response.data.data);
            });
        }
      });
    },
    getResultValue(result) {
      return result.fullName;
    },
    handleSubmit(result) {
      const self = this;

      self.getMemberById(result.id);

      return result.fullName;
    },
    handleFocus() {
      this.focused = true;
    },
    handleBlur() {
      this.focused = false;
    },
  },
};
</script>
