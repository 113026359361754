<template>
  <div v-if="table.rows.length > 0" class="d-flex align-items-center mt-3">
    <div class="d-flex flex-column flex-grow-1">
      <span class="text-dark-75 font-weight-bold">
        Menampilkan {{ showing }} dari {{ table.totalRecords }}
      </span>
    </div>
    <b-pagination
      v-model="table.pageNumber"
      :total-rows="table.rows.length"
      :per-page="table.pageSize"
      align="left"
      size="md"
      class="m-0"
      @change="onPageChange"
    >
      <template #first-text>
        <i class="flaticon2-fast-back icon-sm"></i>
      </template>
      <template #prev-text>
        <i class="flaticon2-back icon-sm"></i>
      </template>
      <template #next-text>
        <i class="flaticon2-next icon-sm"></i>
      </template>
      <template #last-text>
        <i class="flaticon2-fast-next icon-sm"></i>
      </template>
    </b-pagination>
  </div>
</template>

<script>
export default {
  props: {
    serverParams: Object,
    table: Object,
  },
  computed: {
    showing() {
      const self = this;
      let page = self.serverParams.pageNumber - 1;
      return self.serverParams.pageSize * page + self.table.rows.length;
    },
  },
  methods: {
    onPageChange(params) {
      this.$emit("onPageChange", params);
    },
  },
};
</script>
