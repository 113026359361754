<template>
  <fragment>
    <table
      class="table table-head-custom table-vertical-center table-head-bg table-borderless"
    >
      <thead>
        <tr class="text-left">
          <th class="pl-7">
            <span class="text-dark-75">Member</span>
          </th>
          <th><span class="text-dark-75">Jam Checkin</span></th>
          <th><span class="text-dark-75">Loker</span></th>
          <th><span class="text-dark-75">PIN Access</span></th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="table.rows.length == 0">
          <td colspan="3">
            <EmptyList />
          </td>
        </tr>
        <tr v-else v-for="(item, i) in table.rows" v-bind:key="i">
          <td class="pl-0">
            <div class="d-flex align-items-center">
              <div
                v-if="item.member.image != null"
                class="symbol symbol-50 symbol-light mr-4"
              >
                <span class="symbol-label">
                  <img
                    :src="mediaUrl(item.member.image?.label)"
                    class="h-75 align-self-end"
                  />
                </span>
              </div>
              <div>
                <span
                  class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                >
                  {{ item.member.fullName }}
                </span>
                <span class="font-weight-bold d-block">
                  {{ item.member.memberCard.cardNumber }}
                </span>
                <small>{{
                  dateRange(
                    item.member.memberCard?.validFrom,
                    item.member.memberCard?.validUntil
                  )
                }}</small>
              </div>
            </div>
          </td>
          <td>
            <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
              {{ dateFormat(item.checkInTime, "HH:mm") }}
            </span>
            <span class="text-muted font-weight-bold">
              {{ hoursAgo(item.checkInTime) }}
            </span>
          </td>
          <td>
            <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
              {{ item.locker?.label }}
            </span>
          </td>
          <td>
            <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
              {{ item.pin }}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
    <b-modal
      v-model="modal.show"
      title="Identitas Member"
      :no-close-on-backdrop="true"
      :ok-only="true"
      ok-title="Check Out"
      @ok="handleCheckOut"
    >
      <Member :form="modal.customer" />
      <div class="d-flex align-items-center justify-content-between mb-2">
        <span class="font-weight-bold mr-2">Jam :</span>
        <span class="text-muted">
          {{ dateFormat(modal.checkInTime, "HH:mm") }}
        </span>
      </div>
      <div class="d-flex align-items-center justify-content-between mb-2">
        <span class="font-weight-bold mr-2">Durasi :</span>
        <span class="text-muted">{{ duration(modal.checkInTime) }}</span>
      </div>
    </b-modal>
  </fragment>
</template>

<script>
import Member from "@/view/content/common/Member";
import EmptyList from "./EmptyList";
import { mapGetters } from "vuex";
import {
  dateFormat,
  dateRange,
  hoursAgo,
  duration,
  mediaUrl,
} from "@/core/utils";

export default {
  components: {
    EmptyList,
    Member,
  },
  props: {
    table: Object,
  },
  data: () => ({
    modal: {
      show: false,
      member: null,
      checkInId: null,
      checkInTime: null,
    },
  }),
  computed: {
    ...mapGetters(["store"]),
  },
  methods: {
    dateFormat,
    dateRange,
    hoursAgo,
    duration,
    mediaUrl,
    /* openModal(params) {
      const self = this;

      self.modal.show = true;
      self.modal.customer = {
        id: params.member.id,
        fullName: params.member.fullName,
        membershipNumber: params.member.membershipNumber,
        email: params.member.email,
        phone: params.member.phone,
        memberCard: {
          cardNumber: params.member.memberCard.cardNumber,
        },
      };
      self.modal.checkInId = params.id;
      self.modal.checkInTime = params.checkInTime;
    }, */
    handleCheckOut(event) {
      event.preventDefault();
      const self = this;

      if (self.store != null) {
        self.$dialog
          .confirm("Member ini mau checkout ya ?", {
            okText: "Ya, Check Out",
            cancelText: "Ga jadi",
            loader: true,
          })
          .then((dialog) => {
            self.$store
              .dispatch("apis/put", {
                url: `/transaction/${self.store.id}/member/check-out/${self.modal.checkInId}`,
              })
              .then((response) => {
                if (response.error) {
                  self.$message.error({
                    zIndex: 10000,
                    message: response.message,
                  });
                } else {
                  self.$message.success({
                    zIndex: 10000,
                    message: response.message,
                  });

                  self.modal = {
                    show: false,
                    member: null,
                    checkInId: null,
                    checkInTime: null,
                  };
                  self.$emit("onCheckOut");
                }
              })
              .finally(() => dialog.close());
          });
      }
    },
  },
};
</script>
