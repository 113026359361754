<template>
  <fragment>
    <div class="d-flex align-items-center mt-4">
      <div
        class="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center"
      >
        <div
          class="symbol-label"
          :style="`background-image: url('${mediaUrl(form?.image?.label)}')`"
          @click="show"
        />
      </div>
      <div>
        <span
          class="font-weight-bolder font-size-h2 text-dark-75 text-hover-primary"
        >
          {{ form?.fullName ?? "-" }}
        </span>
        <div class="font-size-h6">
          <i class="fas fa-barcode"></i>
          {{ form?.membershipNumber ?? "-" }}
        </div>
        <div class="font-size-h6">
          <i class="far fa-credit-card"></i>
          {{ form?.memberCard.cardNumber ?? "-" }}
        </div>
        <div class="font-size-h6">
          <i class="far fa-calendar-check"></i>
          {{
            dateRange(form?.memberCard.validFrom, form?.memberCard.validUntil)
          }}
        </div>
      </div>
    </div>
    <div class="pt-4">
      <div class="d-flex align-items-center justify-content-between mb-2">
        <span class="font-weight-bold mr-2">Usia :</span>
        <span class="text-hover-primary">
          {{ ageCalculator(form?.dateOfBirth) }}
        </span>
      </div>
      <div class="d-flex align-items-center justify-content-between mb-2">
        <span class="font-weight-bold mr-2">Email :</span>
        <span class="text-hover-primary">
          {{ form?.email ?? "-" }}
        </span>
      </div>
      <div class="d-flex align-items-center justify-content-between mb-2">
        <span class="font-weight-bold mr-2">Nomor Ponsel :</span>
        <span class="text-hover-primary">{{ form?.phone ?? "-" }}</span>
      </div>
    </div>
  </fragment>
</template>

<script>
import { mediaUrl, dateRange, ageCalculator } from "@/core/utils";

export default {
  props: {
    form: Object,
  },
  methods: {
    mediaUrl,
    dateRange,
    ageCalculator,
    show() {
      const self = this;

      if (self.form.image?.label != null) {
        self.$viewerApi({
          images: [self.mediaUrl(self.form.image?.label)],
        });
      }
    },
  },
};
</script>
